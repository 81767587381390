import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  useTheme,
  CircularProgress,
  List,
  ListItem,
  alpha,
} from '@mui/material';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material';
import api from '../utils/api';

const Footer = () => {
  const theme = useTheme();
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await api.get('/settings');
        setSettings(data);
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const footerLinks = [
    { title: 'Home', path: '/' },
    { title: 'Products', path: '/products' },
    { title: 'About', path: '/about' },
    { title: 'Contact', path: '/contact' },
    { title: 'Request Service', path: '/service-requests/new' },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      component="footer"
      sx={{
        py: { xs: 3, sm: 6 },
        px: { xs: 2, sm: 3 },
        mt: 'auto',
        backgroundColor: '#749cb5',
        color: 'white',
        position: 'relative',
        zIndex: theme.zIndex.drawer + 2,
        boxShadow: `0 -4px 6px -1px ${alpha(theme.palette.common.black, 0.1)}`,
        '@media (max-width: 350px)': {
          py: 2,
          px: 1,
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={{ xs: 2, sm: 4 }} alignItems="flex-start">
          {/* Company Info */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography 
              variant="h6" 
              gutterBottom 
              sx={{
                fontWeight: 600,
                '@media (max-width: 350px)': {
                  fontSize: '1rem',
                  marginBottom: 1,
                },
              }}
            >
              {settings?.store?.name || 'JOTH LOGISTICS'}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{
                opacity: 0.9,
                maxWidth: '300px',
                '@media (max-width: 350px)': {
                  fontSize: '0.8rem',
                },
              }}
            >
              {settings?.store?.description || 'Your one-stop shop for quality groceries and household items.'}
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={6} sm={6} md={4}>
            <Typography 
              variant="h6" 
              gutterBottom 
              sx={{
                fontWeight: 600,
                '@media (max-width: 350px)': {
                  fontSize: '1rem',
                  marginBottom: 1,
                },
              }}
            >
              Quick Links
            </Typography>
            <List dense disablePadding>
              {footerLinks.map((link) => (
                <ListItem 
                  key={link.title} 
                  disablePadding 
                  sx={{ 
                    mb: 0.5,
                    '@media (max-width: 350px)': {
                      mb: 0.25,
                    },
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={link.path}
                    sx={{
                      color: 'inherit',
                      textDecoration: 'none',
                      opacity: 0.9,
                      transition: 'opacity 0.2s ease-in-out',
                      '&:hover': {
                        opacity: 1,
                        textDecoration: 'underline',
                      },
                      '@media (max-width: 350px)': {
                        fontSize: '0.8rem',
                      },
                    }}
                  >
                    {link.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={6} sm={6} md={4}>
            <Typography 
              variant="h6" 
              gutterBottom 
              sx={{
                fontWeight: 600,
                '@media (max-width: 350px)': {
                  fontSize: '1rem',
                  marginBottom: 1,
                },
              }}
            >
              Contact Us
            </Typography>
            <List dense disablePadding>
              {settings?.store?.phone && (
                <ListItem 
                  disablePadding 
                  sx={{ 
                    mb: 1,
                    '@media (max-width: 350px)': {
                      mb: 0.5,
                    },
                  }}
                >
                  <PhoneIcon sx={{ mr: 1, fontSize: '1.2rem', opacity: 0.9 }} />
                  <Typography 
                    variant="body2" 
                    sx={{
                      opacity: 0.9,
                      '@media (max-width: 350px)': {
                        fontSize: '0.8rem',
                      },
                    }}
                  >
                    {settings.store.phone}
                  </Typography>
                </ListItem>
              )}
              {settings?.store?.email && (
                <ListItem 
                  disablePadding 
                  sx={{ 
                    mb: 1,
                    '@media (max-width: 350px)': {
                      mb: 0.5,
                    },
                  }}
                >
                  <EmailIcon sx={{ mr: 1, fontSize: '1.2rem', opacity: 0.9 }} />
                  <Typography 
                    variant="body2"
                    sx={{
                      opacity: 0.9,
                      '@media (max-width: 350px)': {
                        fontSize: '0.8rem',
                      },
                    }}
                  >
                    {settings.store.email}
                  </Typography>
                </ListItem>
              )}
              {settings?.store?.address && (
                <ListItem 
                  disablePadding
                  sx={{
                    mb: 1,
                    '@media (max-width: 350px)': {
                      mb: 0.5,
                    },
                  }}
                >
                  <LocationIcon sx={{ mr: 1, fontSize: '1.2rem', opacity: 0.9 }} />
                  <Typography 
                    variant="body2"
                    sx={{
                      opacity: 0.9,
                      '@media (max-width: 350px)': {
                        fontSize: '0.8rem',
                      },
                    }}
                  >
                    {settings.store.address.street}<br />
                    {settings.store.address.city}, {settings.store.address.state}<br />
                    {settings.store.address.country}
                    {settings.store.address.zipCode && ` - ${settings.store.address.zipCode}`}
                  </Typography>
                </ListItem>
              )}
            </List>

            {/* Social Media Icons */}
            <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
              {['facebook', 'twitter', 'instagram', 'linkedin'].map((social) => {
                const Icon = {
                  facebook: FacebookIcon,
                  twitter: TwitterIcon,
                  instagram: InstagramIcon,
                  linkedin: LinkedInIcon,
                }[social];

                return settings?.store?.[social] ? (
                  <IconButton
                    key={social}
                    component="a"
                    href={settings.store[social]}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'white',
                      opacity: 0.9,
                      '&:hover': {
                        opacity: 1,
                        bgcolor: alpha(theme.palette.common.white, 0.1),
                      },
                    }}
                    size="small"
                  >
                    <Icon fontSize="small" />
                  </IconButton>
                ) : null;
              })}
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box
          sx={{
            mt: { xs: 3, sm: 4 },
            pt: 2,
            borderTop: '1px solid',
            borderColor: alpha(theme.palette.common.white, 0.1),
            textAlign: 'center',
          }}
        >
          <Typography 
            variant="body2" 
            sx={{
              opacity: 0.8,
              '@media (max-width: 350px)': {
                fontSize: '0.75rem',
              },
            }}
          >
            &copy; {new Date().getFullYear()} {settings?.store?.name || 'JOTH LOGISTICS'}. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;