import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link as RouterLink, Outlet } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  Divider,
  useTheme,
  alpha,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  Container,
  styled,
  ListItemAvatar,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Inventory as InventoryIcon,
  ShoppingBag as OrdersIcon,
  Person as UserIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Build as ServiceIcon,
  Store as StoreIcon,
  Notifications as NotificationsIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import { fetchNotifications, markAsRead } from '../features/notifications/notificationSlice';
import { useSocket } from '../context/SocketContext';
import { format } from 'date-fns';

const DRAWER_WIDTH = 280;
const APPBAR_HEIGHT = 64;

const NotificationMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 360,
    maxHeight: 480,
    overflow: 'auto',
    marginTop: theme.spacing(1.5),
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
  },
}));

const NotificationItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
}));

const TimeStamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
}));

const getTimeDisplay = (date) => {
  const now = new Date();
  const notificationDate = new Date(date);
  const diffInHours = Math.floor((now - notificationDate) / (1000 * 60 * 60));
  
  if (diffInHours < 24) {
    return format(notificationDate, 'h:mm a');
  } else if (diffInHours < 48) {
    return 'Yesterday';
  } else {
    return format(notificationDate, 'MMM d');
  }
};

const AdminLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mainContentRef = useRef(null);
  const socket = useSocket();
  const { notifications, unreadCount } = useSelector((state) => state.notifications);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    navigate('/login');
  };

  const handleViewStore = () => {
    navigate('/');
  };

  const handleNotificationClick = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorElNotifications(null);
  };

  const handleNotificationItemClick = async (notification) => {
    if (!notification.read) {
      await dispatch(markAsRead([notification._id]));
    }
    handleNotificationClose();
    if (notification.link) {
      navigate(notification.link);
    }
  };

  const handleViewAll = () => {
    navigate('/admin/notifications');
    handleNotificationClose();
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchNotifications({ limit: 10 }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (socket && user) {
      socket.on('notification', (newNotification) => {
        dispatch(fetchNotifications({ limit: 10 }));
      });

      socket.on('orderStatusUpdated', () => {
        dispatch(fetchNotifications({ limit: 10 }));
      });

      socket.on('serviceRequestUpdate', () => {
        dispatch(fetchNotifications({ limit: 10 }));
      });

      return () => {
        socket.off('notification');
        socket.off('orderStatusUpdated');
        socket.off('serviceRequestUpdate');
      };
    }
  }, [socket, user, dispatch]);

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/admin' },
    { text: 'Products', icon: <InventoryIcon />, path: '/admin/products' },
    { text: 'Orders', icon: <OrdersIcon />, path: '/admin/orders' },
    { text: 'Service Requests', icon: <ServiceIcon />, path: '/admin/service-requests' },
    { text: 'Notifications', icon: <NotificationsIcon />, path: '/admin/notifications' },
    { text: 'Users', icon: <UserIcon />, path: '/admin/users' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/admin/settings' },
  ];

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            mb: 2,
            bgcolor: theme.palette.primary.main,
            fontSize: '2rem',
          }}
        >
          {user?.name?.charAt(0)?.toUpperCase()}
        </Avatar>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {user?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Administrator
        </Typography>
      </Box>
      <Divider />
      <List sx={{ px: 2, py: 1, flex: 1 }}>
        {sidebarItems.map((item) => {
          const isActive = window.location.pathname === item.path;
          return (
            <ListItem
              key={item.text}
              component={RouterLink}
              to={item.path}
              onClick={() => isMobile && handleDrawerToggle()}
              sx={{
                mb: 1,
                borderRadius: 2,
                color: isActive ? 'primary.main' : 'text.primary',
                bgcolor: isActive ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                '&:hover': {
                  bgcolor: isActive
                    ? alpha(theme.palette.primary.main, 0.15)
                    : alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: isActive ? 'primary.main' : 'text.primary',
                  minWidth: 45,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontWeight: isActive ? 600 : 400,
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                }}
              />
              {item.text === 'Orders' && (
                <Typography variant="body2" sx={{ 
                  fontWeight: 600, 
                  color: 'error.main',
                  ml: 2,
                  minWidth: 20,
                  textAlign: 'center',
                  bgcolor: 'error.light',
                  color: 'error.contrastText',
                  borderRadius: '10px',
                  px: 1
                }}>
                  {notifications.filter(n => 
                    (n.type === 'ORDER_PLACED' || n.type === 'ORDER_STATUS_UPDATE') && 
                    !n.read
                  ).length}
                </Typography>
              )}
              {item.text === 'Service Requests' && (
                <Typography variant="body2" sx={{ 
                  fontWeight: 600, 
                  color: 'error.main',
                  ml: 2,
                  minWidth: 20,
                  textAlign: 'center',
                  bgcolor: 'error.light',
                  color: 'error.contrastText',
                  borderRadius: '10px',
                  px: 1
                }}>
                  {notifications.filter(n => 
                    n.type === 'SERVICE_REQUEST_UPDATE' && 
                    !n.read
                  ).length}
                </Typography>
              )}
              {item.text === 'Notifications' && (
                <Typography variant="body2" sx={{ 
                  fontWeight: 600, 
                  color: 'error.main',
                  ml: 2,
                  minWidth: 20,
                  textAlign: 'center',
                  bgcolor: 'error.light',
                  color: 'error.contrastText',
                  borderRadius: '10px',
                  px: 1
                }}>
                  {unreadCount}
                </Typography>
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
      {/* Admin Header */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          bgcolor: 'background.paper',
          boxShadow: theme.shadows[1],
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 1, sm: 2, md: 3 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="primary"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { md: 'none' },
                '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
              }}
            >
              Admin Dashboard
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 0.5, sm: 1 } }}>
            <Tooltip title="View Store">
              <IconButton
                onClick={handleViewStore}
                sx={{
                  color: 'primary.main',
                  '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) },
                }}
              >
                <StoreIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications">
              <IconButton
                onClick={handleNotificationClick}
                sx={{
                  color: 'text.primary',
                  '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) },
                }}
              >
                <Badge badgeContent={unreadCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <NotificationMenu
              anchorEl={anchorElNotifications}
              open={Boolean(anchorElNotifications)}
              onClose={handleNotificationClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Typography variant="h6">Notifications</Typography>
              </Box>
              <List sx={{ p: 0 }}>
                {notifications.length > 0 ? (
                  <>
                    {notifications.map((notification) => (
                      <div key={notification._id}>
                        <NotificationItem onClick={() => handleNotificationItemClick(notification)}>
                          <ListItemAvatar>
                            <Avatar src={notification.image} alt="">
                              {!notification.image && <NotificationsIcon />}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={notification.title}
                            secondary={
                              <Box component="span" sx={{ display: 'block' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                  {notification.message}
                                </Typography>
                                <TimeStamp>
                                  {getTimeDisplay(notification.createdAt)}
                                </TimeStamp>
                              </Box>
                            }
                          />
                        </NotificationItem>
                        <Divider />
                      </div>
                    ))}
                    <Box sx={{ p: 1, textAlign: 'center' }}>
                      <Typography
                        component="button"
                        onClick={handleViewAll}
                        sx={{
                          color: 'primary.main',
                          border: 'none',
                          background: 'none',
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                      >
                        View All
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ p: 3, textAlign: 'center' }}>
                    <Typography color="text.secondary">No notifications</Typography>
                  </Box>
                )}
              </List>
            </NotificationMenu>
            <Tooltip title="Account">
              <IconButton
                onClick={handleMenu}
                sx={{
                  p: 0.5,
                  border: '2px solid',
                  borderColor: 'primary.main',
                  '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) },
                }}
              >
                <Avatar
                  sx={{
                    width: { xs: 28, sm: 32 },
                    height: { xs: 28, sm: 32 },
                    bgcolor: 'primary.main',
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                  }}
                >
                  {user?.name?.charAt(0)?.toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" color="error" />
                </ListItemIcon>
                <Typography color="error">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Side Panel */}
      <Box
        component="nav"
        sx={{
          width: { md: DRAWER_WIDTH },
          flexShrink: { md: 0 },
        }}
      >
        {/* Mobile drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              bgcolor: 'background.paper',
              boxShadow: theme.shadows[8],
              border: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* Desktop drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              bgcolor: 'background.paper',
              borderRight: '1px solid',
              borderColor: 'divider',
              height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
              top: APPBAR_HEIGHT,
              overflowY: 'visible',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        ref={mainContentRef}
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          bgcolor: alpha(theme.palette.primary.main, 0.02),
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: alpha(theme.palette.primary.main, 0.05),
          },
          '&::-webkit-scrollbar-thumb': {
            background: alpha(theme.palette.primary.main, 0.2),
            borderRadius: '4px',
            '&:hover': {
              background: alpha(theme.palette.primary.main, 0.3),
            },
          },
        }}
      >
        <Toolbar /> {/* Spacer for AppBar */}
        <Container
          maxWidth={false}
          sx={{
            p: { xs: 1, sm: 2, md: 3 },
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default AdminLayout;