import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Divider,
  useTheme,
  Chip,
  Tooltip,
  alpha,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  Circle as CircleIcon,
} from '@mui/icons-material';
import api from '../../utils/api';
import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from '../../components/Pagination';

const Notifications = () => {
  const theme = useTheme();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchNotifications();
  }, [currentPage]);

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/notifications/admin?page=${currentPage}&limit=${itemsPerPage}`);
      setNotifications(data.notifications);
      setTotalPages(data.totalPages);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to fetch notifications');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/notifications/${id}`);
      setNotifications(notifications.filter(notif => notif._id !== id));
      if (selectedNotification?._id === id) {
        setDetailsOpen(false);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to delete notification');
    }
  };

  const handleView = (notification) => {
    setSelectedNotification(notification);
    setDetailsOpen(true);
    if (!notification.isRead) {
      markAsRead(notification._id);
    }
  };

  const markAsRead = async (id) => {
    try {
      await api.put(`/notifications/${id}/read`);
      setNotifications(notifications.map(notif => 
        notif._id === id ? { ...notif, isRead: true } : notif
      ));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(date).toLocaleString('en-US', options);
  };

  const getNotificationColor = (type) => {
    switch (type) {
      case 'order':
        return theme.palette.info.main;
      case 'user':
        return theme.palette.success.main;
      case 'product':
        return theme.palette.warning.main;
      default:
        return theme.palette.primary.main;
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Notifications
      </Typography>

      <Paper elevation={2}>
        <List>
          {notifications.map((notification, index) => (
            <React.Fragment key={notification._id}>
              {index > 0 && <Divider />}
              <ListItem
                sx={{
                  bgcolor: notification.isRead ? 'transparent' : alpha(theme.palette.primary.main, 0.05),
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                  },
                  transition: 'background-color 0.2s',
                  cursor: 'pointer',
                }}
                onClick={() => handleView(notification)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  {!notification.isRead && (
                    <CircleIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 12,
                        mr: 1,
                      }}
                    />
                  )}
                </Box>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" component="span">
                        {notification.title}
                      </Typography>
                      <Chip
                        size="small"
                        label={notification.type}
                        sx={{
                          bgcolor: getNotificationColor(notification.type),
                          color: '#fff',
                        }}
                      />
                    </Box>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(notification.createdAt)}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="View Details">
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleView(notification);
                      }}
                      sx={{ mr: 1 }}
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(notification._id);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ))}
          {notifications.length === 0 && (
            <ListItem>
              <ListItemText
                primary={
                  <Typography align="center" color="text.secondary">
                    No notifications found
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </Paper>

      {totalPages > 1 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </Box>
      )}

      <Dialog
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {selectedNotification && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {selectedNotification.title}
                <Chip
                  size="small"
                  label={selectedNotification.type}
                  sx={{
                    bgcolor: getNotificationColor(selectedNotification.type),
                    color: '#fff',
                  }}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" paragraph>
                {selectedNotification.message}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatDate(selectedNotification.createdAt)}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDetailsOpen(false)}>Close</Button>
              <Button
                color="error"
                onClick={() => {
                  handleDelete(selectedNotification._id);
                  setDetailsOpen(false);
                }}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Notifications;
