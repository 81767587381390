import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
  useTheme,
} from '@mui/material';
import {
  LocalGroceryStore as StoreIcon,
  Security as SecurityIcon,
  LocalShipping as ShippingIcon,
  Support as SupportIcon,
} from '@mui/icons-material';

const About = () => {
  const theme = useTheme();

  const features = [
    {
      icon: <StoreIcon sx={{ fontSize: 40,  }} />,
      title: 'Quality Products',
      description:
        'We source the finest products to ensure you get the best quality for your money.',
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      title: 'Secure Shopping',
      description:
        'Your security is our priority. Shop with confidence using our secure payment systems.',
    },
    {
      icon: <ShippingIcon sx={{ fontSize: 40 }} />,
      title: 'Fast Delivery',
      description:
        'We ensure quick and reliable delivery to get your products to you when you need them.',
    },
    {
      icon: <SupportIcon sx={{ fontSize: 40 }} />,
      title: '24/7 Support',
      description:
        'Our customer support team is always ready to help you with any questions or concerns.',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: 'white' }}>
      {/* Hero Section */}
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{ fontWeight: 'bold', backgroundColor: 'white', color: 'black' }}
        >
          About Us
        </Typography>
        <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
          Your Trusted Source for Quality Groceries
        </Typography>
      </Box>

      {/* Mission Statement */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          Our Mission
        </Typography>
        <Typography variant="body1" paragraph align="center">
          At Joth Logistics, Our mission is to build trusted
           relationships, providing tailored logistics services
            that meet the unique needs of each costumer and driving
             long-term success through collaboration and innovation
        </Typography>
      </Box>

      {/* Features Grid */}
      <Grid container spacing={4} sx={{ mb: 8 }}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 2,
              }}
            >
              <Avatar
                sx={{
                  width: 80,
                  height: 80,
                  bgcolor: theme.palette.primary.main,
                  mb: 2,
                }}
              >
                {feature.icon}
              </Avatar>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* History Section */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          Our Story
        </Typography>
        <Typography variant="body1" paragraph>
          Founded in 2023, Joth Logistics has grown from a small local shop to
          a trusted online grocery destination. Our journey began with a simple
          idea: to make quality groceries more accessible to everyone. Today, we
          serve thousands of satisfied customers, providing them with the best
          products and service.
        </Typography>
        <Typography variant="body1" paragraph>
          We take pride in our carefully curated selection of products, working
          directly with suppliers to ensure the highest quality standards. Our
          team is dedicated to providing you with a seamless shopping experience,
          from browsing our website to receiving your delivery.
        </Typography>
      </Box>

      {/* Values Section */}
      <Box>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          Our Values
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3} sx={{ mb: 4 }}>
            <Box sx={{ textAlign: 'center',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              p: 2,
             }}>
              <Typography variant="h6" gutterBottom>
                Quality
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We never compromise on the quality of our products, ensuring you
                get the best value for your money.
              </Typography>
            </Box>
          </Grid>
          <Grid  item xs={12} sm={6} md={3} sx={{ mb: 4 }}>
            <Box sx={{ textAlign: 'center',
              height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 2,
             }}>
              <Typography variant="h6" gutterBottom>
                Customer First
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Your satisfaction is our top priority. We're here to serve you
                and make your shopping experience exceptional.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ mb: 4 }}>
            <Box sx={{ textAlign: 'center',
              height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 2,
             }}>
              <Typography variant="h6" gutterBottom>
                Community
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We're committed to supporting our local community and building
                lasting relationships with our customers.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default About; 