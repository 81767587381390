import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PromotionalBanner from '../components/PromotionalBanner';
import ProductCard from '../components/ProductCard';
import CategoryCard from '../components/CategoryCard';
import LoadingSpinner from '../components/LoadingSpinner';
import {
  Fastfood as FastfoodIcon,
  LocalDrink as DrinkIcon,
  Restaurant as SnacksIcon,
  LocalPizza as DairyIcon,
  Kitchen as KitchenIcon,
  Spa as ProduceIcon,
  Home as HomeIcon,
  Face as PersonalCareIcon,
} from '@mui/icons-material';
import api from '../utils/api';

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const categories = [
    { name: 'Groceries', icon: <FastfoodIcon sx={{ fontSize: 40 }} /> },
    { name: 'Beverages', icon: <DrinkIcon sx={{ fontSize: 40 }} /> },
    { name: 'Snacks', icon: <SnacksIcon sx={{ fontSize: 40 }} /> },
    { name: 'Dairy', icon: <DairyIcon sx={{ fontSize: 40 }} /> },
    { name: 'Produce', icon: <ProduceIcon sx={{ fontSize: 40 }} /> },
    { name: 'Household', icon: <HomeIcon sx={{ fontSize: 40 }} /> },
    { name: 'Kitchen', icon: <KitchenIcon sx={{ fontSize: 40 }} /> },
    { name: 'Personal Care', icon: <PersonalCareIcon sx={{ fontSize: 40 }} /> },
  ];

  useEffect(() => {
    fetchFeaturedProducts();
  }, []);

  const fetchFeaturedProducts = async () => {
    try {
      setLoading(true);
      const response = await api.get('/products?featured=true&limit=24');
      setFeaturedProducts(Array.isArray(response.data) ? response.data : response.data.products || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching featured products:', err);
      setError('Failed to load featured products');
      setFeaturedProducts([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <Box>
      {/* Promotional Banner */}
      <PromotionalBanner />

      {/* Categories Section */}
      <Container maxWidth="lg" sx={{ mb: 6 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            mb: 3,
            fontWeight: 600,
            textAlign: 'center',
            color: '#f3c331',
          }}
        >
          Shop by Category
        </Typography>
        <Grid container spacing={2}>
          {categories.map((category) => (
            <Grid item xs={6} sm={4} md={3} key={category.name}>
              <CategoryCard category={category.name} icon={category.icon} />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Featured Products Section */}
      <Container maxWidth="lg" sx={{ 
        mb: { xs: 3, sm: 6 },
        '@media (max-width: 350px)': {
          px: 1,
        }
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: { xs: 2, sm: 3 },
          flexWrap: 'wrap',
          gap: 1
        }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: 600,
              color: theme.palette.primary.main,
              '@media (max-width: 350px)': {
                fontSize: '1.4rem',
              }
            }}
          >
            Featured Products
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/products')}
            sx={{
              '@media (max-width: 350px)': {
                fontSize: '0.8rem',
                py: 0.5,
                px: 1,
              }
            }}
          >
            View All
          </Button>
        </Box>

        {error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : featuredProducts.length === 0 ? (
          <Typography align="center" color="text.secondary">
            No featured products available
          </Typography>
        ) : (
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {featuredProducts.map((product) => (
              <Grid item xs={6} sm={6} md={3} key={product._id}>
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      {/* Special Offers Section */}
      {featuredProducts.some(product => product.discount > 0) && (
        <Box
          sx={{
            bgcolor: theme.palette.background.paper,
            py: { xs: 3, sm: 6 },
            mb: { xs: 3, sm: 6 },
            '@media (max-width: 350px)': {
              py: 2,
            }
          }}
        >
          <Container maxWidth="lg" sx={{
            '@media (max-width: 350px)': {
              px: 1,
            }
          }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                mb: { xs: 2, sm: 3 },
                fontWeight: 600,
                textAlign: 'center',
                color: theme.palette.primary.main,
                '@media (max-width: 350px)': {
                  fontSize: '1.4rem',
                  mb: 1.5,
                }
              }}
            >
              Special Offers
            </Typography>
            <Grid container spacing={{ xs: 1, sm: 3 }}>
              {featuredProducts
                .filter((product) => product.discount > 0)
                .slice(0, 12)
                .map((product) => (
                  <Grid item xs={6} sm={6} md={3} key={product._id}>
                    <ProductCard product={product} />
                  </Grid>
                ))}
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Home; 