import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import bannerImage from '../assets/banner.jpeg';

const PromotionalBanner = () => {
  const theme = useTheme();

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{
        position: 'relative',
        height: { xs: '300px', md: '400px' },
        borderRadius: 2,
        overflow: 'hidden',
        mb: 4,
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      />

      {/* Content */}
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: { xs: 'center', md: 'flex-start' },
          p: 4,
          color: 'white',
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography
          component={motion.h1}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
          variant="h2"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            mb: 2,
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
          }}
        >
          Christmas Sale
        </Typography>
        <Typography
          component={motion.p}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          variant="h5"
          sx={{ mb: 4,
             color: 'white',
             textShadow: '1px 1px 2px rgba(0,0,0,0.3)' }}
        >
          Up to 50% off on selected items
        </Typography>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <Button
            variant="contained"
            size="large"
            color="secondary"
            component={RouterLink}
            to="/products"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: '1.2rem',
              borderRadius: 8,
              boxShadow: theme.shadows[10],
            }}
          >
            Shop Now
          </Button>
        </motion.div>
      </Box>
    </Box>
  );
};

export default PromotionalBanner; 