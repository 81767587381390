import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  Alert,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { login, clearError } from '../features/auth/authSlice';
import LoadingSpinner from '../components/LoadingSpinner';

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading, error } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [localError, setLocalError] = useState(null);

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    // Only navigate if user exists and there's no error
    if (user && !error) {
      navigate(redirect);
    }
  }, [user, error, navigate, redirect]);

  // Update local error when redux error changes
  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Clear errors when user starts typing
    if (localError) {
      setLocalError(null);
      dispatch(clearError());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(login(formData)).unwrap();
    } catch (err) {
      // Error is already set in the state via the rejected action
      console.error('Login failed:', err);
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <Container
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      maxWidth="sm"
      sx={{ py: 8 }}
    >
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>

        {localError && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            onClose={() => {
              setLocalError(null);
              dispatch(clearError());
            }}
          >
            {localError}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>

          <Box sx={{ textAlign: 'center' }}>
            <Link
              component={RouterLink}
              to="/forgot-password"
              color="primary"
              sx={{ display: 'block', mb: 2 }}
            >
              Forgot Password?
            </Link>
            <Typography variant="body2" color="text.secondary">
              Don't have an account?{' '}
              <Link
                component={RouterLink}
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
                color="primary"
              >
                Register here
              </Link>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;